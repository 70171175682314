<template>
  <v-select
    v-model='selectedUsers'
    @input='updateSelectedUser'
    @search='searchUsers'
    label='name'
    ref='userSearch'
    :placeholder='placeholderString'
    :close-on-select='!isMultiple'
    :filterable='false'
    :multiple='isMultiple'
    :options='filteredOptions'>
    <template v-slot:option='option'>
      <div class='custom-field-option py-2 text-sm'>
        {{ option.name }} ({{ option.email }})
      </div>
    </template>
    <template v-slot:no-options>
      <div class='py-3 text-gray-500 text-center'>이름 또는 이메일을 입력하세요.</div>
    </template>
    <template v-slot:spinner>
      <i v-if='loading' class='el-icon-loading p-2' />
    </template>
  </v-select>
</template>

<script>
import { mapActions } from 'vuex'
import debounce       from 'debounce'

export default {
  name: 'UserSearch',
  props: [
    'userIds',
    'userNames',
    'isMultiple',
    'role',
    'placeholder',
  ],
  data () {
    return {
      filteredOptions: [],
      selectedUsers: [],
      loading: false,
    }
  },
  watch: {
    // making user select options (id, name_and_email(= field.display_value, user_names))
    userIds: {
      handler: function (newVal) {
        if (newVal) {
          this.selectedUsers = []
          if (this.isMultiple) {
            this.selectedUsers = newVal.map(userId => {return {id: userId, name: ''}})
            if (this.userNames) {
              this.userNames.split(',').forEach((name, index) => {
                if (this.selectedUsers[index]) {
                  this.selectedUsers[index].name = name
                }
              })
            }
          } else {
            this.selectedUsers = [{id: newVal, name: this.userNames}]
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    selectedUserIds () {
      return (this.selectedUsers.length > 0) ? this.selectedUsers.map(user => user.id) : []
    },
    selectedUserNames () {
      return (this.selectedUsers.length > 0) ? this.selectedUsers.map(user => user.name).join(',') : ''
    },
    placeholderString () {
      return this.placeholder ? this.placeholder : ''
    }
  },
  methods: {
    ...mapActions('users', [
      'searchUsersByText',
    ]),
    searchUsers (text) {
      let filterParams = {search_text: text}
      if (this.role) {
        filterParams['role'] = this.role
      }
      this.loading = true
      if (text) {
        this.searchUsersByText(filterParams).then(resp => {
          this.filteredOptions = resp
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    updateSelectedUser (users) {
      if (this.isMultiple) {
        this.$emit('update-selected-user', this.selectedUserIds, this.selectedUserNames)
      } else {
        if (users) {
          this.$emit('update-selected-user', {user_id: users.id, user_name: users.name})
        }
      }
    },
  },
  created () {
    this.searchUsers = debounce(this.searchUsers, 300)
  },
}
</script>
