<template>
  <div>
    <h1 v-if='editMode' class='text-2xl mb-16'>
      {{ name }}
    </h1>
    <div class='my-4 flex'>
      <label class='form-label'>펀드명</label>
      <input type='text' class='form-input form-input-text' v-model='name' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>펀드약칭</label>
      <input type='text' class='form-input form-input-text' v-model='short_name' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>펀드코드</label>
      <input type='text' class='form-input form-input-text' v-model='fund_code' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>예탁원 펀드코드</label>
      <input type='text' class='form-input form-input-text' v-model='ksd_fund_code' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>설정일</label>
      <el-date-picker
        v-model='start_date'
        format="yyyy-MM-dd"
        type='date' placeholder='Pick a day'>
      </el-date-picker>
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>만기일</label>
      <el-date-picker
        v-model='end_date'
        format="yyyy-MM-dd"
        type='date' placeholder='Pick a day'>
      </el-date-picker>
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>수탁은행</label>
      <entity-search v-model='entity_id'
        class='inline-block'
        :entity-name='entity_name'
        :entity-id='entity_id'
        @update-selected-entity='updateSelectedEntity' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>담당자</label>
      <contact-search
        class='w-64'
        :contact-id='contact_id'
        :contact-name='contact_name'
        @update-selected-contact='updateContactId'
        />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>수탁기관명</label>
      <input type='text' class='form-input form-input-text' v-model='account_name' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>수탁기관코드</label>
      <input type='text' class='form-input form-input-text' v-model='account_number' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>투자구분</label>
      <v-select
        v-model='target_asset'
        class='w-64'
        label='type'
        :options='targetAssetTypes'
        :close-on-select='true'>
        <template v-slot:option='option'>
          {{ option.type }}
        </template>
      </v-select>
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>공모여부</label>
      <el-switch
        class='h-auto'
        v-model='is_public_offering'
        active-text='공모'
        inactive-text='사모'>
      </el-switch>
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>IPO 참가 여부</label>
      <el-switch
        class='h-auto'
        v-model='is_ipo_participation'
        active-text='참여'
        inactive-text='미참여'>
      </el-switch>
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>전략</label>
      <input type='text' class='form-input form-input-text uppercase' v-model='strategy' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>주 매니저</label>
      <user-search
        class='w-64'
        :user-ids='main_manager'
        :user-names='main_manager_name'
        @update-selected-user='updateMainManagerId' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>부 매니저</label>
      <user-search
        class='w-64'
        :user-ids='sub_manager'
        :user-names='sub_manager_name'
        @update-selected-user='updateSubManagerId' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>주목적 투자</label>
      <v-select
        v-model='target_investment'
        class='w-auto'
        label='type'
        :options='targetInvestmentTypes'
        :close-on-select='false'
        multiple>
        <template v-slot:option='option'>
          {{ option.type }}
        </template>
      </v-select>
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>비시장성목표</label>
      <input type='number' class='form-input text-right' v-model='non_marketable_objectives' />
    </div>
    <div class='my-4 flex'>
      <label class='form-label'>종목별(제안서) 비중</label>
      <input type='number' class='form-input text-right' v-model='proposal_weight' />
    </div>
    <button
      v-if='createMode'
      class='inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
      style='background-color: #223645;'
      @click='submitCreateFund'>
      생성
    </button>
    <div v-if='editMode' class='w-full flex flex-row'>
      <button
        class='mr-4 inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
        style='background-color: #223645;'
        @click='submitUpdateFund'>
        저장
      </button>
      <button
        class='inline-block py-3 px-16 text-red-700 rounded-md font-bold border hover:border-red-400 hover:shadow-md'
        @click='submitDeleteFund'>
        삭제
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields }  from 'vuex-map-fields'
import ContactSearch  from '@/components/contacts/ContactSearch.vue'
import UserSearch     from '@/components/UserSearch.vue'
import EntitySearch   from '@/components/EntitySearch.vue'
import dayjs from 'dayjs'

export default {
  name: 'FundDetails',
  components: {
    ContactSearch,
    UserSearch,
    EntitySearch,
  },
  data () {
    return {
      targetAssetTypes: [
        '집합',
        '벤처',
        '하이일드',
        '고유',
      ],
      targetInvestmentTypes: [
        'Multi-Strategy',
        '하이일드 펀드',
        '프로젝트 펀드',
        '코스닥벤처',
        '상장사 메자닌/비상장',
        '상장사 메자닌',
        '상장사 메자닌 only',
        '비상장 only',
        'IT/SW/컨텐츠',
        'IT',
        '바이오',
        '디지털',
        '그린',
      ],
    }
  },
  computed: {
    ...mapState('contacts', [
      'contacts',
    ]),
    ...mapState('funds', [
      'funds',
    ]),
    ...mapFields('funds', [
      'drilldownFund',
      'drilldownFund.name',
      'drilldownFund.short_name',
      'drilldownFund.fund_code',
      'drilldownFund.ksd_fund_code',
      'drilldownFund.start_date',
      'drilldownFund.end_date',
      'drilldownFund.entity_id',
      'drilldownFund.entity_name',
      'drilldownFund.contact_id',
      'drilldownFund.contact_name',
      'drilldownFund.account_name',
      'drilldownFund.account_number',
      'drilldownFund.target_asset',
      'drilldownFund.is_public_offering',
      'drilldownFund.managers.main_manager',
      'drilldownFund.managers.sub_manager',
      'drilldownFund.main_manager_name',
      'drilldownFund.sub_manager_name',
      'drilldownFund.target_investment',
      'drilldownFund.non_marketable_objectives',
      'drilldownFund.proposal_weight',
      'drilldownFund.is_ipo_participation',
      'drilldownFund.strategy',
    ]),
    createMode () {
      return !this.editMode
    },
    editMode () {
      return Number.isInteger(this.drilldownFund.id) && this.drilldownFund.id > 0
    },
    selectedFund () {
      return this.funds.find(fund => fund.id === this.drilldownFund.id)
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('funds', [
      'createFund',
      'deleteFund',
      'updateFund',
    ]),
    ...mapMutations('funds', [
      'setDrilldownFund',
    ]),
    doneEditing () {
      this.sidepanelClose()
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    reformatDates () {
      this.start_date = dayjs(this.start_date).format('YYYY-MM-DD')
      this.end_date = dayjs(this.end_date).format('YYYY-MM-DD')
    },
    submitCreateFund () {
      this.reformatDates()
      this.createFund(this.drilldownFund)
    },
    submitDeleteFund () {
      this.deleteFund(this.drilldownFund)
    },
    submitUpdateFund () {
      this.reformatDates()
      this.updateFund(this.drilldownFund)
    },
    updateContactId (newContact) {
      this.contact_id = newContact.id
      this.contact_name = newContact.contact_name
      this.phone_number = newContact.phone_number
      this.setDrilldownFund(this.drilldownFund)
    },
    updateMainManagerId (newUser) {
      this.main_manager = newUser.user_id
      this.main_manager_name = newUser.user_name
      this.setDrilldownFund(this.drilldownFund)
    },
    updateSubManagerId (newUser) {
      this.sub_manager = newUser.user_id
      this.sub_manager_name = newUser.user_name
      this.setDrilldownFund(this.drilldownFund)
    },
    updateSelectedEntity (newEntity) {
      this.entity_id = newEntity.entityId
      this.entity_name = newEntity.entityName
    }
  },
  beforeDestroy () {
    this.sidepanelClose()
  }
}
</script>

<style lang='scss' scoped>

.form-label {
  @apply text-gray-600 w-64 leading-10;
}
.form-input-text {
  @apply w-2/3;
}

</style>
